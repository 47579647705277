import { BaseService } from "../base.service";
import { ErrorWrapper, ResponseWrapper } from "../util";

export class ToolsService extends BaseService {
    static get entity() {
        return "tools" //??
    }

    static apiClient(options) {
        const baseURL = process.env.VUE_APP_API_URL;
        return this.initApiClient({ baseURL, ...options });
    }

    // static async getUrlsHeaders(params = {}) {
    //     console.log(params)
    //     try {
    //         const response = await this.apiClient().get('tools/headers', { params });
    //         return new ResponseWrapper(response, response.data);
    //     } catch (error) {
    //         const message = error.response.data ? error.response.data.error : error.response.statusText;
    //         throw new ErrorWrapper(error, message);
    //     }
    // }

    static async getUrlsHeaders(data = {}) {
        try {
            const response = await this.apiClient({ auth: true }).post('tools/headers', { data });
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            throw new ErrorWrapper(error);
        }
    }

}